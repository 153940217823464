import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

type AppConfigs = {
  provinces: Province[];
  setListProvinces: (_provinces: Province[]) => void;
  selectedProvince: Province | null;
  setSelectedProvince: (_province: Province | undefined) => void;
  rescueTypes: RescueType[];
  setRescueTypes: (_rescueType: RescueType[]) => void;
  setAppConfig: (_provinces: Province[], _rescueType: RescueType[]) => void;
};

export const useAppConfigsStore = create<AppConfigs>()(
  devtools(
    persist(
      (set) => ({
        provinces: [],
        setListProvinces: (_provinces: Province[]) =>
          set(() => ({ provinces: _provinces })),
        selectedProvince: null,
        setSelectedProvince: (province: Province | undefined) =>
          set(() => ({
            selectedProvince: province,
          })),
        rescueTypes: [],
        setRescueTypes: (_rescueType) =>
          set(() => ({ rescueTypes: _rescueType })),
        setAppConfig: (_provinces, _rescueType) =>
          set(() => ({
            provinces: _provinces,
            rescueTypes: _rescueType,
          })),
      }),
      {
        name: "app-config-store",
        partialize: (state) => ({ selectedProvince: state.selectedProvince }),
      }
    )
  )
);
