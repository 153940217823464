import clsx from "clsx";
import { twMerge } from "tw-merge";

// NOTE: đây là trường hợp ngoại lệ vì pagekage gốc không định nghĩa type cho params mà lại export qua interface
// do đó, type được định nghĩa và export ngay trong util.
export type ClassValue = ClassArray | ClassDictionary | string | number | null | boolean | undefined;
export type ClassDictionary = Record<string, unknown>;
export type ClassArray = ClassValue[];

export function composeClassName(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
