import React from "react";
import { SendOutlined } from "@ant-design/icons";
import { useRouter } from "next/navigation";
import { PrimaryButton } from ".";

export default function SendSupportRequestButton() {
  const router = useRouter();

  return (
    <PrimaryButton onClick={() => router.push("/request-support")}>
      <SendOutlined className="mr-2" /> Gửi yêu cầu cứu trợ
    </PrimaryButton>
  );
}
